<template>
    <v-snackbar
            v-model="open"
            :top="top"
            :color="color"
            :bottom="bottom"
            rounded="lg"
            :timeout="timeout"
    >
        <v-row class="mx-0" align="center">
            <v-icon size="20" v-if="icon" class="mr-4">{{ icon }}</v-icon>
            <div class="paragraph font-weight">{{ text }}</div>
        </v-row>
        <template v-slot:action>
            <v-btn
                    dark
                    icon
                    @click="open = false"
                    small
            >
                <v-icon size="20">mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
    export default {
        name: "SnackBar",
        computed: {
            open: {
                get(){
                    return this.$store.getters['snackbar/open']
                },
                set(val){
                    this.$store.commit('snackbar/CHANGE_OPEN_VAL', val)
                }
            },
            color(){
                return this.$store.getters['snackbar/color']
            },
            icon(){
                return this.$store.getters['snackbar/icon']
            },
            text(){
                return this.$store.getters['snackbar/text']
            },
            top(){
                return this.$store.getters['snackbar/top']
            },
            bottom(){
                return this.$store.getters['snackbar/bottom']
            },
            timeout(){
                return this.$store.getters['snackbar/timeout']
            }
        }
    }
</script>
